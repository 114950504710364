/* eslint-env browser */
import { useCallback, useEffect, useRef } from 'react';
export const useDebounce = (fn, ms) => {
    const timerRef = useRef(null);
    const debouncedFun = (args) => new Promise((resolve) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        timerRef.current = setTimeout(() => {
            resolve(fn(args));
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }, ms);
    });
    const teardown = useCallback(() => timerRef.current && clearTimeout(timerRef.current), []);
    useEffect(() => () => teardown(), [teardown]);
    return debouncedFun;
};
